export const googleNewAdsData = {
  homePage: {
    left: {
      slotId: '23202123844',
      addId: 'HP_LEFT_300*300',
      divId: 'div-gpt-ad-1728032294895-0',
      dimension: [300, 250]
    },
    hotStory: {
      web: {
        slotId: '23202123844',
        addId: 'HP_HOTSTORY_WEB',
        divId: 'div-gpt-ad-1728032153198-0',
        dimension: [468, 60]
      },
      mWeb: {
        slotId: '23202123844',
        addId: 'HP_MWEB_HOTSTORY_320*50',
        divId: 'div-gpt-ad-1728033970592-0',
        dimension: [320, 50]
      }
    }
  },
  commentary: {
    web: {
      slotId: '23202123844',
      addId: 'COMMENTARY_728*90',
      divId: 'div-gpt-ad-1728034707093-0',
      dimension: [728, 90]
    },
    mWeb: {
      slotId: '23202123844',
      addId: 'COMMENTARY_MWEB_300*300',
      divId: 'div-gpt-ad-1728035238873-0',
      dimension: [300, 300]
    }
  }
}
