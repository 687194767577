export const allRoutes = {
  seriesHome: (id) => `/${id}`,
  seriesNews: (id) => `${id}news`,
  seriesVideos: (id) => `${id}videos`,
  seriesFantasyArticles: (id) => `${id}fantasy-articles`,
  seriesFixtures: (id) => `${id}fixtures`,
  seriesStandings: (id) => `${id}points-table`,
  seriesStats: (id) => `${id}stats`,
  seriesTeams: (id) => `${id}teams`,
  seriesSquads: (id) => `${id}squads`,
  seriesArchives: (id) => `${id}archives`,
  seriesHomeAsFixtures: (id) => `/${id}`,
  seriesFantasyTips: (id) => `${id}fantasy-tips`,
  fixtures: '/live-cricket-score',
  fixturesNew: '/cricket/live-score',
  fixturesUpcoming: '/live-cricket-score/upcoming',
  fixturesUpcomingNew: '/cricket/live-score/upcoming',
  fixturesRecent: '/live-cricket-score/recent',
  fixturesRecentNew: '/cricket/live-score/recent',
  cricketSchedule: '/upcoming-cricket-schedule',
  cricketSeries: '/series',
  cricketSeriesNew: '/cricket/series',
  cricketSeriesArchive: '/cricket-series-archive',
  matchDetail: (slug) => `/${slug}`,
  matchDetailScorecard: (slug) => `${slug}full-scorecard`,
  matchDetailCommentary: (slug) => `/${slug}/commentary`,
  matchDetailStandings: (slug) => `${slug}points-table`,
  matchDetailNews: (slug) => `${slug}news`,
  matchDetailUpcoming: (slug) => `${slug}fixtures-and-results`,
  matchDetailResult: (slug) => `${slug}results`,
  matchDetailFantasyTips: (slug) => `${slug}fantasy-tips`,
  matchDetailOvers: (slug) => `${slug}overs`,
  matchDetailLiveStats: (slug) => `${slug}live-stats`,
  signUp: '/sign-up',
  signIn: '/sign-in',
  profile: '/profile',
  profileEdit: '/profile/edit',
  saveForLater: '/profile/saved-for-later',
  forgotPassword: '/forgot-password',
  home: '/',
  verifyPhoneNumber: '/verify-phone-number',
  search: '/search',
  searchNews: '/search/news',
  searchVideo: '/search/video',
  searchSeries: '/search/series',
  searchPlayer: '/search/players',
  searchTeam: '/search/team',
  fantasyCricketTips: '/fantasy-cricket-tips/',
  iccRankings: '/icc-rankings',
  cricketVideosNew: 'cricket/videos',
  cricketVideos: 'cricket-videos',
  playlist: 'playlist',
  cricketVideo: 'videos',
  cricketSeriesPages: 'series',
  authors: '/authors',
  authorDetail: (slug) => `/author/${slug}`,
  termsAndConditions: '/terms-of-use',
  copyrightsNotice: '/copyright-notice',
  writeForUs: '/write-for-us',
  privacyPolicy: '/privacy-policy',
  disclaimer: '/disclaimer',
  dmca: '/dmca',
  changePassword: '/change-password',
  advertiseWithUs: '/advertise-with-us',
  careers: '/careers',
  aboutUs: '/about-us',
  contactUs: '/contact-us',
  gdprCompilance: '/gdpr-compilance',
  affiliate: '/affiliate',
  feedback: '/feedback'
}

export const matchDetailTabSlug = ['full-scorecard', 'news', 'fixtures-and-results', 'fantasy-tips', 'overs', 'points-table', 'commentary', 'live-stats', 'live-blog']
export const cricketSeriesSlug = ['standings', 'stats', 'teams', 'squads', 'fantasy-tips', 'fixtures']
