// Stats sub pages
const statsPage = {
  stBhsi: 'stats',
  stBha: 'stats',
  stBhs: 'stats',
  stBmc: 'stats',
  stBmr6i: 'stats',
  stBm4: 'stats',
  stBmr4i: 'stats',
  stBmr: 'stats',
  stBmri: 'stats',
  stBmr50: 'stats',
  stBms: 'stats',
  stBtwt: 'stats',
  stBberi: 'stats',
  stBba: 'stats',
  stBber: 'stats',
  stBbsr: 'stats',
  stBbsri: 'stats',
  stBfiw: 'stats',
  stBbbf: 'stats',
  stBmrci: 'stats',
  stBfow: 'stats',
  stBm: 'stats',
  stTtr: 'stats',
  stTtr100: 'stats',
  stTtw: 'stats',
  stBmf: 'stats'
}

const statsSubPages = {
  stBhsi: 'highest-strike-rates-innings',
  stBha: 'highest-average',
  stBtwt: 'top-wicket-takers',
  stBberi: 'best-economy-rates-innings',
  stBba: 'best-averages',
  stTtr: 'total-runs',
  stTtr100: 'team-most-centuries',
  stTtw: 'total-wickets',
  stBber: 'best-economy-rates',
  stBbsr: 'best-strike-rates',
  stBbsri: 'best-strike-rates-Innings',
  stBfiw: 'five-wickets',
  stBhs: 'highest-strike-rates',
  stBmc: 'most-centuries',
  stBmr6i: 'most-sixes-innings',
  stBm4: 'most-fours',
  stBmr4i: 'most-fours-innings',
  stBbbf: 'best-bowling-figures',
  stBmrci: 'most-runs-conceded-in-an-innings',
  stBfow: 'four-wickets',
  stBmf: 'team-most-fifties',
  stBmr: 'most-runs',
  stBmri: 'highest-individual-score',
  stBmr50: 'most-fifties',
  stBms: 'most-sixes',
  stBm: 'maidens'
}

export const ENUM_SEO_SUBTYPE = {
  h: 'home',
  n: 'news',
  v: 'videos',
  f: 'fixtures',
  s: 'standings',
  t: 'teams',
  sq: 'squads',
  ar: 'archives',
  ft: 'fantasy-tips',
  sc: 'score-card',
  o: 'overs',
  far: 'fixtures-and-results',
  r: 'results',
  c: 'commentary',
  u: 'upcoming',
  st: 'stats',
  ...statsPage
}

export const ENUM_SEO_SUBTYPE_WITH_STATS = {
  ...ENUM_SEO_SUBTYPE,
  ...statsSubPages
}

export const ENUM_SERIES_CATEGORY_PAGES = {
  h: 'home',
  n: 'news',
  v: 'videos',
  f: 'fixtures',
  s: 'standings',
  st: 'stats',
  t: 'teams',
  sq: 'squads',
  ar: 'archives',
  ft: 'fantasy-tips'
}

export const ENUM_CRICKET_SERIES_PAGES = {
  f: 'fixtures',
  s: 'standings',
  st: 'stats',
  t: 'teams',
  sq: 'squads',
  ft: 'fantasy-tips'
}

export const ENUM_CRICKET_TEAM_PAGES = {
  o: 'overview',
  n: 'news',
  m: 'match'
}

export const ENUM_CRICKET_PLAYER_PAGES = {
  o: 'overview',
  n: 'news',
  m: 'matches',
  st: 'stats'
}

export const ENUM_CRICKET_MATCH_PAGES = {
  i: 'info',
  n: 'news',
  v: 'videos',
  f: 'fixtures',
  s: 'points-table',
  st: 'stats',
  t: 'teams',
  sq: 'squads',
  ar: 'archives',
  // ft: 'fantasy-tips',
  sc: 'full-scorecard',
  o: 'overs',
  far: 'fixtures-and-results',
  r: 'results',
  c: 'commentary',
  u: 'upcoming',
  p: 'photos',
  ls: 'live-stats',
  lb: 'live-blog'
}
