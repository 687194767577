import { useEffect, useState } from 'react'

const useOnMouseAndScroll = () => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    window.addEventListener('mousemove', load, { passive: true })
    window.addEventListener('scroll', load, { passive: true })

    function load() {
      if (isLoaded) return true
      setIsLoaded(true)
    }

    return () => {
      window.removeEventListener('mousemove', load)
      window.removeEventListener('scroll', load)
    }
  }, [])
  return {
    isLoaded
  }
}

export default useOnMouseAndScroll
