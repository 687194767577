export const miniScorecard = `
dStartDate
iBattingTeamId
iMatchId
nPriority
nOrder
oVenue {
  sLocation
  sName
}
nLatestInningNumber
oSeries {
  _id
  sTitle
  sSrtTitle
  iCategoryId
  nTotalTeams
  oSeo {
    aCustomSeo {
      eTabType
      sSlug
    }
    sSlug
  }
}
oSeriesSeo {
  aCustomSeo {
    sSlug
    eTabType
  }
  sSlug
}
oSeriesSeos {
  oSeo {
    eSubType
  }
}
oTeamScoreA {
  oTeam {
    _id
    sAbbr
    oImg {
      sUrl
    }
    sTitle
  }
  sScoresFull
  sScores
  sOvers
  iTeamId
}
oTeamScoreB {
  oTeam {
    _id
    sAbbr
    oImg {
      sUrl
    }
    sTitle
  }
  sScoresFull
  sScores
  sOvers
  iTeamId
}
sStatusNote
sTitle
sSubtitle
sStatusStr
oStatus {
  sValue
  sText
}
sLiveGameStatusStr
oLiveGameStatus {
  sText
  sValue
}
oSeo {
  sSlug
  sDescription
  sTitle
}
iWinnerId
oMatchSeos {
  oSeo {
    eSubType
    sSlug
  }
}
`
